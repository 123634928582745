/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {


  // Function declarations

  function attachCarousels() {
  
    // Make sure the plugin has loaded
    if ($.fn.carousel) {

      // User activated carousels
      $('.images-carousel').carousel({
        'maxItems': 8,
        'Interval': 7500,
        'autoScroll': true
      });

       $('.posts-carousel').carousel({
        'maxItems': 8,
        'Interval': 10000,
        'autoScroll': true
      });

    }
    
    /*

    if ($.fn.matchHeight) {
      // find out if we're on an apple device as they have issues calculating widths straight away..
      var sDeviceAgent = navigator.userAgent.toLowerCase();
      var bIsAppleDevice = sDeviceAgent.match(/(iphone|ipod|ipad)/);
      setTimeout(uniformCarouselHeights, 1000);
    }

    */
  }

  /**
   *  Corrects carousel heights to ensure they're all the same
   */
  function uniformCarouselHeights() { 
    
    $('ul.images-carousel').each(function() {
      // The iPad is having issues with calculating the
      // height of the clones so we're ignoring them
      //$('li.item:not(.cloned) div.grid', this).equalHeights();
      $('li.carousel--item', this).matchHeight();
    });
  }

  function attachFixedHeader() {
          
    var header       = $('header'),
        page         = $(window);

    if (header.length > 0) {
      page.scroll(function() {
        if (page.scrollTop() > 100) {
          header.addClass('fixed');
        } else {
          header.removeClass('fixed');
        }
      });
    }
  }

    function attachFixedSubnav() {
          
      var sidebar       = $('.sidebar-inner'),
          parent,
          page,
          offset,
          parentoffset,
          parentheight,
          topPadding;

      if (sidebar.length > 0) {

        parent        = sidebar.parent().parent();
        page          = $(window);
        offset        = sidebar.offset().top;
        parentoffset  = parent.offset().top;
        parentheight  = parent.height();
        topPadding    = 130;

        page.scroll(function() {

          // offset        = sidebar.offset().top;
          parentoffset  = parent.offset().top;
          parentheight  = parent.height();

          if (page.scrollTop() + topPadding + sidebar.outerHeight() > parentheight + parentoffset + 80) {
            sidebar.addClass('page-end');
          } else if (page.scrollTop() >= offset - topPadding) {
            sidebar.removeClass('page-end');
            sidebar.addClass('fixed');
          } else {
            sidebar.removeClass('page-end');
            sidebar.removeClass('fixed');
          }

          sidebar.width(sidebar.parent().width());
        });

        page.scroll();

        $(window).resize(function(){
          sidebar.removeClass('fixed');
          offset        = sidebar.offset().top;
          page.scroll();
        });
      }
    }


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).on("click", ".navbar-toggle", function() {
            var menu = $(".primary-navbar");
            if (menu.is(":hidden")) {
                menu.slideDown("600");
                $(this).addClass("open");
            } else {
                menu.slideUp("600");
                $(this).removeClass("open");
            }
            return false;
        });

        $(document).on("click", "#menu-primary-menu a[href$='/shop/']", function() {

            if ("none" === $('.navbar-toggle').css("display")) {
              var menu = $(".shop-navbar");
              if (menu.is(":hidden")) {
                  menu.slideDown("600");
                  $(this).addClass("open");
              } else {
                  menu.slideUp("600");
                  $(this).removeClass("open");
              }
              $(".startproject-navbar").slideUp();
              return false;
            }

        });


        $(document).on("click", ".product-item a.quick-view", function( e ) {

          e.stopPropagation();

          var $url = $(this).attr('href') + " #product-summary";

          $('#lightbox-content').load($url, function() {

              var popup_content = '<div class="white-popup"><div class="style-details">' + $(this).html() + '</div></div>';

              $.magnificPopup.open({
                items: {
                      src: popup_content,
                      type: 'inline'
                }
              });

          });

          return false;

        });

        var allPanels = $('.checkout-accordion .accordion-content').hide();

        allPanels.eq(0).show( function () {

          $(this).prev().addClass('active');

        });

        /*
        $('.product-item a.quick-view').magnificPopup({
          type: 'ajax',
          ajax: {
            settings: null, // Ajax settings object that will extend default one - http://api.jquery.com/jQuery.ajax/#jQuery-ajax-settings
            // For example:
            // settings: {cache:false, async:false}

            cursor: 'mfp-ajax-cur', // CSS class that will be added to body during the loading (adds "progress" cursor)
            tError: '<a href="%url%">The content</a> could not be loaded.' //  Error message, can contain %curr% and %total% tags if gallery is enabled
          },
          callbacks: {
            parseAjax: function(mfpResponse) {
              // mfpResponse.data is a "data" object from ajax "success" callback
              // for simple HTML file, it will be just String
              // You may modify it to change contents of the popup
              // For example, to show just #some-element:
              // mfpResponse.data = $(mfpResponse.data).find('#some-element');

              // mfpResponse.data must be a String or a DOM (jQuery) element

              console.log('Ajax content loaded:', mfpResponse);
            },
            ajaxContentAdded: function() {
              // Ajax content is loaded and appended to DOM
              // Re-bind dropdown scripts
              $('.single_variation_wrap').show();
            }
          }
        });
        */

        $(document).on("click", ".style-item a.quick-view", function(e) {

          e.stopPropagation();

          var style_content = $( '#styleinformation_' + $( this ).data('style') );

          if ( style_content.length > 0 ) {

            var popup_content = '<div class="white-popup"><div class="style-details">' + style_content.html() + '</div></div>';

            $.magnificPopup.open({
              items: {
                  src: popup_content,
                  type: 'inline'
              }
            });
          }

          return false;
          
        });

        attachCarousels();

        attachFixedHeader();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        attachFixedSubnav();

        $('.gallery, .product .images').each(function() { // the containers for all your galleries
          $(this).magnificPopup({
              delegate: 'a', // the selector for gallery item
              type: 'image',
              mainClass: 'mfp-with-zoom',
              gallery: {
                enabled:true
              },
              zoom: {
                enabled: false, // By default it's false, so don't forget to enable it

                duration: 300, // duration of the effect, in milliseconds
                easing: 'ease-in-out', // CSS transition easing function

                // The "opener" function should return the element from which popup will be zoomed in
                // and to which popup will be scaled down
                // By defailt it looks for an image tag:
                opener: function(openerElement) {
                  // openerElement is the element on which popup was initialized, in this case its <a> tag
                  // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                  return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
              }
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'subscription': {
      init: function() {
        // JavaScript to be fired on the about us page


        var master_durations = $('input[name="attribute_duration"]');
        var selected_duration = $('input[name="attribute_duration"]:checked');
        var package_durations = $('input[name^="attribute_duration"]');

        if ( selected_duration.length > 0 ) {
          $('input[name^="attribute_duration"][value="' + selected_duration.val() + '"]').prop('checked',true).closest('tr').addClass('duration-selected');
        }

        package_durations.each(function() {

          // On click change all related checkboxes to be the same value
          $(this).on( "click", function() {
            var option = $(this).val();
            $('tr.duration-selected').removeClass('duration-selected');
            $('input[name^="attribute_duration"][value="' + option + '"]').each( function() {
              $(this).prop('checked',true);
              $(this).closest('tr').addClass('duration-selected');
            });

          });

          // Set the master checkbox -- needs to be done last

        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // OVERWRITE THE DEFAULT JAVASCRIPT SO THAT RADIO BUTTONS WORK INSTEAD OF DROPDOWNS
        SetupSubscriptionActions2();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
